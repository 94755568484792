export const $c = {
    appname:'昭美社区智慧数据大屏',//名称
    themeColor:'#0d0c08',//背景色
    fontColor:'#fff',//在主题色背景之上搭配使用的字体颜色
	pollingInterval: 1000*300,//在没有websocket的情况下，页面信息轮询更新的时间间隔（ms）
	externalLiveBaseUrl: 'https://zmsq.domidodo.cn/jk/', //外网和内网直播BaseUrl
	intranetLiveBaseUrl: 'http://192.168.31.241:10180/',
	useExternalNetwork: true, //配置是否使用外网环境
    videoTimeLimit: 300000,//在外网环境下为节约流量，播放每一个监控视频的时间限制，单位毫秒
	highAgeIs: 80, //在需要判断居民是否高龄的场合中，大于等于该值的就判定为高龄，在此修改值即可，便于维护
    inspection: true //设置为true时当前打包的版本为迎检版，该项目中的相关数据会按领导要求显示而不按实际情况显示
}

//localStorage的操作方法
export const $db = {
    set(obj){
        for(let key in obj){
            window.localStorage.setItem(key, obj[key]);
        }
    },
    get(key){
        return window.localStorage[key];
    },
    clear(){
        window.localStorage.clear();
    }
}

//常用工具函数
export const $u = {
    /**
     * 获取数组中的最大数值和最小数值
     * @param {Array[number]} arr
     */
    getMaxAndMin(arr){
        if(!arr.length){
            return [];
        }else if(arr.length === 1){
            return [arr[0], arr[0]];
        }
        let max = arr[0]
          , min = arr[0];
        for(let i = 1;i < arr.length;i++){
            if(arr[i] > max){
                max = arr[i];
            }else if(arr[i] < min){
                min = arr[i];
            }
        }
        return [min, max];
    },
    /**
     * 限制输入值的大小
     * @param {number} v 输入值
     * @param {number} min 最小值
     * @param {number} max 最大值
     */
    limitVal(v, min, max){
        if(typeof(v) !== 'number'){
            return v;
        }
        let res = v;
        if(res < min){
            res = min;
        }else if(res > max){
            res = max;
        }
        return res;
    },
    //获取时间日期
    formatDate(){
        let date = new Date();
        let y = date.getFullYear()+'';
        let M = date.getMonth() + 1;
        M += '';
        let d = date.getDate()+'';
        let h = date.getHours().toString();
        let m = date.getMinutes().toString();
        let s = date.getSeconds().toString();
        let w = date.getDay().toString();
        let week;
        switch(w){
            case '0':
                week = '星期日';
                break;
            case '1':
                week = '星期一';
                break;
            case '2':
                week = '星期二';
                break;
            case '3':
                week = '星期三';
                break;
            case '4':
                week = '星期四';
                break;
            case '5':
                week = '星期五';
                break;
            case '6':
                week = '星期六';
                break;
            default:
                week = '警告：日期时间获取错误';
        }
        if(M.length < 2){
            M = '0' + M;
        }
        if(d.length < 2){
            d = '0' + d;
        }
        if(h.length < 2){
            h = '0' + h;
        }
        if(m.length < 2){
            m = '0' + m;
        }
        if(s.length < 2){
            s = '0' + s;
        }
        let format = y + '-' + M + '-' + d + ' ' + h + ':' + m + ':' + s;
        return [y,M,d,h,m,s,week,format];//输出数组内容分别为年、月、日、时、分、秒、星期和格式化后的字符串时间日期
    },
    /**
     * 将一个数字每3位用一个逗号分开
     * @param {number} num 输入整数
     */
    toThousand(num){
        return num.toString().replace(/\d+/, function(n){
            return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
        })
    },
	/**
     * 根据当前的系统年月日计算年龄
     * @param {string} dt 输入字符串日期格式'yyyy-MM-dd'
     */
	getAge(dt){
		if(typeof(dt) == 'string'){
			let arr = dt.split('-');
			let y = parseInt(arr[0]);
			let m = parseInt(arr[1]);
			let d = parseInt(arr[2]);
			let cDate = new Date();
			let cy = cDate.getFullYear()+'';
			let cM = cDate.getMonth() + 1;
			cM += '';
			let cd = cDate.getDate()+'';
			let age = 0;
			age += cy - y;
			if(cM < m){
				age--;
			}else if(cM == m){
				if(cd < d){
					age--;
				}
			}
			if(age < 0){
				age = 0;
			}
			return age;
		}else{
			return 0;
		}
	},
    /**
     * 将中文数字转换成阿拉伯数字
     * @param {string} str 输入字符串中文数字例如'九十九'
     */
    parseChineseNumber(str){
        if(typeof(str) == 'number'){
            return str;
        }else if(typeof(str) != 'string'){
            return 0;
        }
        let sl = ['零','一','二','三','四','五','六','七','八','九'];
        let cnUnit = [{
            name: '十',
            value: 10,
            secUnit: false
        },{
            name: '百',
            value: 100,
            secUnit: false
        },{
            name: '千',
            value: 1000,
            secUnit: false
        },{
            name: '万',
            value: 10000,
            secUnit: true
        },{
            name: '亿',
            value: 100000000,
            secUnit: true
        }];

        let strs = str.split('');
        let rtn = 0;
        let number = 0;

        for(let i = 0;i < strs.length;i++){
            let s = strs[i];
            let x = sl.indexOf(s);
            if(x >= 0){
                number = x;
            }else{
                for(let j = 0;j < cnUnit.length;j++){
                    let u = cnUnit[j]
                    if(s == u.name){
                        if(s == '十' && number == 0){
                            number = 1;
                        }
                        if(!u.secUnit){
                            number *= u.value;
                            rtn += number;
                        }else{
                            rtn += number;
                            rtn *= u.value;
                        }
                        number = 0;
                    }
                }
            }
        }
        rtn += number;
        return rtn;
    },
	//刷新页面
    reload(){
        window.location.reload();
    },
    //图片转Base64的方法，是一个异步方法
    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
}