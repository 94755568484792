<template>
    <div style="height:100%; width: 100%; background: @bgColor " class="appContainer">
        <div class="header" :style="titleBackgroundImg">
            <div class="lighter" :style="{animation: 'moveToLeft 18s infinite',background: activePage==2?'radial-gradient(rgba(255, 59, 59, 0.2) 20%, rgba(255, 208, 59, 0) 80%)':'radial-gradient(rgba(255, 208, 59, 0.2) 20%, rgba(255, 208, 59, 0) 80%)'}"></div>
            <div class="lighter" :style="{animation: 'moveToRight 18s infinite',background: activePage==2?'radial-gradient(rgba(255, 59, 59, 0.2) 20%, rgba(255, 208, 59, 0) 80%)':'radial-gradient(rgba(255, 208, 59, 0.2) 20%, rgba(255, 208, 59, 0) 80%)'}"></div>
            <div class="weatherBox">
                <div class="weatherIcon">
                    <img src="@/assets/common/Component 47.png" class="weatherIconImg" alt="">
                </div>
                <div class="temperatureBox">
                    <span class="temperature">{{temperature}}</span>
                    <span class="oc">&ensp;℃</span>
                </div>
            </div>
            <div class="outerTitleBox">
				<div class="titleBox">
					<div class="decorationBox">
						<img src="@/assets/common/导航栏装饰元素.png" class="decorationIcon" alt="">
					</div>
					<span class="titleFont">
						昭美社区智慧平台
					</span>
					<span class="yiBaitiTitleFont">
						ꍋꂰꋥꁕꀑꁮꐽꈉ
					</span>
					<div class="decorationBox">
						<img src="@/assets/common/导航栏装饰元素右.png" class="decorationIcon" alt="">
					</div>
				</div>
            </div>
            <div class="basicInfoBox">
                <div class="dateBox">{{year}}/{{month}}/{{day}}</div>
                <div class="weekDayBox">{{weekday}}</div>
                <div class="timeBox">{{hour}}:{{minute}}:{{second}}</div>
                <div class="exitButton" @click.stop="handleExit">
                    <div class="exitIconBox">
                        <img src="@/assets/common/退出.png" class="exitIcon" alt="">
                    </div>
                    <div class="exitText">退出</div>
                </div>
            </div>
        </div>
        <div class="page">
			<div class="webGLModelContainer">
				<!-- 将社区webgl模型整合到这里 -->
			</div>
			<div class="pageContainer">
				<router-view :selectRangeDate='selectRangeDate'></router-view>
			</div>
        </div>
        <div class="bottomNavigator" :style="navBackgroundImg">
            <div class="selectRange">
                <div :class="activePage == 4?'selectButtonActive':'selectButton'" @click="()=>{selectPage(4);$router.push('page4')}">
                    <div :class="activePage == 4?'buttonBgActive':(activePage == 2?'buttonBgRed':'buttonBg')">乡村振兴</div>
                </div>
                <div :class="activePage == 2?'selectButtonActive':'selectButton'" @click="()=>{selectPage(2);$router.push('page2')}">
                    <div :class="activePage == 2?'buttonBgActiveRed':'buttonBg'">智慧党建</div>
                </div>
                <div :class="activePage == 1?'selectButtonActive':'selectButton'" @click="()=>{selectPage(1);$router.push('page1')}">
                    <div :class="activePage == 1?'communityBgActive':(activePage == 2?'communityBgRed':'communityBg')">智慧社区</div>
                </div>
                <div :class="activePage == 3?'selectButtonActive':'selectButton'" @click="()=>{selectPage(3);$router.push('page3')}">
                    <div :class="activePage == 3?'buttonBgActive':(activePage == 2?'buttonBgRed':'buttonBg')">社区管理</div>
                </div>
                <div :class="activePage == 5?'selectButtonActive':'selectButton'" @click="()=>{selectPage(5);$router.push('page5')}">
                    <div :class="activePage == 5?'buttonBgActive':(activePage == 2?'buttonBgRed':'buttonBg')">民生关爱</div>
                </div>
            </div>
        </div>
        <div class="bottomLogo">
            <div class="bottomLogoBox">
                <img style="width: 100%; height: 100%" src="@/assets/common/huiyanLogo.png" alt="">
            </div>
            <div class="huiyanText">
                <span style="line-height: 1.11vh; vertical-align: bottom;">辉炎科技</span>
            </div>
            <div class="huiyanNumber">18181733484</div>
        </div>
    </div>
</template>

<script>

export default {
    name: '',
    data() {
        return {
            activeName: 'month',// 默认显示近一月
            modal: false,
            flag: false,
            selectRangeDate: [],
            resizeFn: null,
            timer: null,
            year: '2023',
            month: '10',
            day: '10',
            hour: '00',
            minute: '00',
            second: '00',
            weekday: '',
			temperature: '--',
            pwd: '',
            activePage: 1,
			titleBackgroundImg: {},
			navBackgroundImg: {},
			communityButtonImg: {},
            otherButtonImg: {},
			hourlyUpdateTimer: null,
            loginRefreshTimer: null
        }
    },
    created(){
    },
    mounted() {
        window.addEventListener('resize', this.resizeFn);
		// 切换页面事件。事件源：每个页面里面都有
		/* this.$eventBus.$on('switchPage', n => {
            console.log('切换页面事件：'+n);
			this.activePage = n;
		}); */
        this.timer = setInterval(() => {
            let dateArr = this.$u.formatDate();
            this.year = dateArr[0];
            this.month = dateArr[1];
            this.day = dateArr[2];
            this.hour = dateArr[3];
            this.minute = dateArr[4];
            this.second = dateArr[5];
            this.weekday = dateArr[6];
        }, 1000);
		this.setTemperature();
		//每10分钟更新一次环境温度数据
		this.hourlyUpdateTimer = setInterval(this.setTemperature,600000);
		this.titleBackgroundImg = {
			backgroundImage: 'url('+require('@/assets/common/顶部导航栏.png')+')'
		}
		this.navBackgroundImg = {
			backgroundImage: 'url('+require('@/assets/common/Frame.png')+')'
		}
    },
	watch:{
		activePage(page){
			if(page == 2){
				//监听到页面切换到第2页即智慧党建，将上下导航栏和导航按钮内容全部变成红色主题色
				this.titleBackgroundImg = {
					backgroundImage: 'url('+require('@/assets/common/顶部导航栏红.png')+')'
				}
				this.navBackgroundImg = {
					backgroundImage: 'url('+require('@/assets/common/FrameRed.png')+')'
				}
			
			}else{
				//其余情况切换回黄色主题色
				this.titleBackgroundImg = {
					backgroundImage: 'url('+require('@/assets/common/顶部导航栏.png')+')'
				}
				this.navBackgroundImg = {
					backgroundImage: 'url('+require('@/assets/common/Frame.png')+')'
				}
			}
		}
	},
    beforeDestroy(){
        clearInterval(this.timer);
		clearInterval(this.hourlyUpdateTimer);
        clearInterval(this.loginRefreshTimer);
        return false;
    },
    methods: {
        selectPage(page){
            this.$eventBus.$emit('closeDetailWindow');
            let loginStatus = this.$db.get('token');
            if(!loginStatus){
                this.$router.push('login');
            }else{
                this.activePage = page;
            }
            return false;
        },
		setTemperature(){
			this.$api.getEnvironmentData().then(res => {
				this.temperature = res.data.data.temperature;
			})
			.catch((err) => {
				console.log(err);
			});
		},
		handleExit(){
			this.$db.clear();
            this.$router.push('login');
		}
    },
}
</script>

<style lang="less">

@import "~@/assets/less/index.less";

@keyframes moveToLeft {
    0% {
        left: 35%
    }
    30% {
        left: -30%
    }
    100% {
        left: -30%
    }
}
@keyframes moveToRight {
    0% {
        left: 35%
    }
    30% {
        left: 100%
    }
    100% {
        
        left: 100%
    }
}

.header {
    width: 100%;
    height: 6.94vh;
    background-color: inherit;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 1px 0px rgba(26,19,36,0.24);
    display: flex;
    align-items: center;
    justify-content: space-between;
	position: fixed;
	top: 0;
	overflow: hidden;
	z-index: 100;

    .lighter {
        position: absolute;
        width: 30%;
        height: 83%;
        top: 0;
        z-index: 120;
    }

    .weatherBox {
        height: 2.78vh;
		flex:1;
		display: flex;
		align-items: center;
		
        .weatherIcon {
            height: 2.78vh;
            aspect-ratio: 1/1;
			margin-left: 5%;
            .weatherIconImg {
                width: 100%;
                height: 100%;
            }
        }
        .temperatureBox {
            height: 2.1vh;
            display: flex;
			align-items: flex-end;
            color: #fff;
			margin-left: 0.21%;
			transform: translateY(0.19vh);

            .temperature {
                font-family: 'titleFont', sans-serif;
                font-size: 2.1vh;
				line-height: 2.1vh;
                letter-spacing: 1px;
            }
            .oc {
                font-family: 'pingfangLt', sans-serif;
                font-size: 1.1vh;
                line-height: 1.6vh;
                
            }
        }

    }

    .outerTitleBox {
        height: 3.43vh;
		flex:1;
		
		.titleBox {
			margin:auto;
			height:100%;
			width: 76.41%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			
			.decorationBox {
				width: 9.06%;
				height: 2.78vh;
				display: inline-block;
				.decorationIcon {
					width: 100%;
					height: 100%;
					vertical-align: top;
				}
			}
			
			.titleFont {
				font-family: 'titleFont', sans-serif;
				font-size: 2.96vh;
				line-height: 3.43vh;
				font-weight: 400;
                text-shadow: 0px 0px 1.41% rgba(255,208,59,0.7);
				background: linear-gradient(180deg, #FFFFFF 42%, #FFD03B 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
			
			.yiBaitiTitleFont {
                font-family: 'msyi', sans-serif;
				font-size: 2.22vh;
				line-height: 2.59vh;
				font-weight: 400;
				background: linear-gradient(180deg, #FFFFFF 42%, #FFD03B 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

    }
	.basicInfoBox {
		flex:1;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .dateBox {
            font-family: 'pingfangSc', sans-serif;
            font-size: 1.3vh;
            height: 1.3vh;
            line-height: 1.3vh;
            color: #fff;
            font-weight: 400;
            margin-right: 1.26%;
        }

        .weekDayBox{
            font-family: 'pingfangSc', sans-serif;
            font-size: 1.3vh;
            height: 1.3vh;
            line-height: 1.3vh;
            color: #fff;
            font-weight: 400;
            margin-right: 1.26%;
        }

        .timeBox {
            font-family: 'timerFont', sans-serif;
            font-size: 2.22vh;
            height: 2.22vh;
            line-height: 2.22vh;
            color: #fff;
            font-weight: normal;
            margin-right: 5%;
            vertical-align: bottom;
            transform: translateY(-0.3vh);
        }

        .exitButton {
            width: 11.25%;
            height: 2.69vh;
            border: 1px solid rgba(212,222,236,0.2);
            border-radius: 0.37vh;
            margin-right: 5%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transform: translateY(-0.3vh);

            .exitIconBox {
                width: 1.48vh;
                height: 1.48vh;
                .exitIcon {
                    width: 100%;
                    height: 100%;
                    border: 1px solid rgba(212,222,236,0.2);
                    vertical-align: top;
                }
            }

            .exitText {
                font-size: 1.3vh;
                font-family: 'pingfangM', sans-serif;
                height: 1.3vh;
                line-height: 1.3vh;
                color: #fff;
                margin-left: 5.56%;
            }
        }

	}

}



.bottomNavigator {
    width: 100%;
    height: 6.67vh; 
	background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: inherit;
    -webkit-background-size: 100%;
    -o-background-size: 100%;
    position: fixed;
    bottom: 0px;
	z-index: 100;

    .selectRange {
        height: 100%;
        width: 40.65%;
        margin:auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .selectButton {
            position: relative;
            width: 16.21%;
            height: 4.5vh;
            cursor:pointer;
            
            .buttonBg {
                width: 100%;
                height: 100%;
                position: absolute;
                background-image: url('~@/assets/common/Group.png');
                background-size: 100%;
                background-color: inherit;
                background-position: center;
                background-repeat: no-repeat;
                line-height: 4.5vh;
                font-family: 'titleFont', sans-serif;
                font-weight: 400;
                color: #ffd03b;
                text-align: center;
                font-size: 2.1vh;
            }

            .buttonBgRed {
                width: 100%;
                height: 100%;
                position: absolute;
                background-image: url('~@/assets/common/GroupRed.png');
                background-size: 100%;
                background-color: inherit;
                background-position: center;
                background-repeat: no-repeat;
                line-height: 4.5vh;
                font-family: 'titleFont', sans-serif;
                font-weight: 400;
                color: #ff3b3b;
                text-align: center;
                font-size: 2.1vh;
            }

            .communityBg {
                width: 100%;
                height: 100%;
                position: absolute;
                background-image: url('~@/assets/common/community.png');
                background-size: 100%;
                background-color: inherit;
                background-position: center;
                background-repeat: no-repeat;
                line-height: 4.5vh;
                font-family: 'titleFont', sans-serif;
                font-weight: 400;
                color: #ffd03b;
                text-align: center;
                font-size: 2.1vh;
            }
            .communityBgRed {
                width: 100%;
                height: 100%;
                position: absolute;
                background-image: url('~@/assets/common/communityRed.png');
                background-size: 100%;
                background-color: inherit;
                background-position: center;
                background-repeat: no-repeat;
                line-height: 4.5vh;
                font-family: 'titleFont', sans-serif;
                font-weight: 400;
                color: #ff3b3b;
                text-align: center;
                font-size: 2.1vh;
            }
        }

        .selectButtonActive {
            position: relative;
            width: 16.21%;
            height: 4.5vh;
            cursor: pointer;
            line-height: 4.5vh;
            color: #fff;
            font-family: 'titleFont', sans-serif;
            font-weight: 400;
            text-align: center;
            font-size: 2.1vh;

            .buttonBgActive {
                width: 100%;
                height: 100%;
                position: absolute;
                background-image: url('~@/assets/common/GroupActive.png');
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                text-shadow: 0px 0px 9px rgba(255,208,59,0.7);
            }
            .buttonBgActiveRed {
                width: 100%;
                height: 100%;
                position: absolute;
                background-image: url('~@/assets/common/GroupActiveRed.png');
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                text-shadow: 0px 0px 9px rgba(255,59,59,0.8);
            }

            .communityBgActive {
                width: 100%;
                height: 100%;
                position: absolute;
                background-image: url('~@/assets/common/communityActive.png');
                background-size: 100%;
                background-color: inherit;
                background-position: center;
                background-repeat: no-repeat;
                text-shadow: 0px 0px 9px rgba(255,208,59,0.7);
                
            }
        }
		
    }
}

.bottomLogo {
    position: absolute;
    bottom: 0.83vh;
    right: 1.04vw;
    width: 10.57vw;
    height: 4.36vh;
    display: flex;
    z-index: 99999;
    align-items: center;

    .bottomLogoBox {
        height: 2.74vh;
        aspect-ratio: 1/1;
        margin-left: 0.625vw;
    }

    .huiyanText {
        font-family: 'DOUYU', sans-serif;
        color: #fff;
        font-size: 1.11vh;
        font-weight: normal;
        height: 1.57vh;
        line-height: 1.57vh;
        margin-left: 0.1vw;
        vertical-align: bottom;
        display: flex;
        align-items: center;
        transform: translateY(0.25vh);
    }
    .huiyanNumber {
        font-family: 'pingfangSc', sans-serif;
        font-size: 1.11vh;
        color: #fff;
        height: 1.57vh;
        line-height: 1.57vh;
        margin-left: 0.78125vw;
        vertical-align: top;
    }

}

.page {
	position: absolute;
	margin-top: 5.83vh;
    height: 94.17vh;
	width: 100%;
	z-index: 10;
	pointer-events: none;
	.pageContainer {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: none;
		z-index: 12;
    & > div > div{
      pointer-events: all;
    }
	}
	
	.webGLModelContainer {
		position: absolute;
		background: none;
		width: 100%;
		height: 100%;
		z-index: 11;
	}

}
</style>
