//requests.js
/* 
    后台系统所用到的接口调用方法统一放在这里，便于开发过程中修改和管理
*/

import ajax from './ajax.js';

/* 
    每一个api均使用配置对象进行配置，其中的字段有：
    data: 发送请求时所传的参数，选填
    url: 请求路径，如果是mock请求请填写请求的文件名，必填。
    method: 请求方法，必填
    auth: 是否在请求头中携带token，不填默认为false
    mock: 该请求是否是一个mock请求，不填默认为false。mock数据统一存放在'public/mock'文件夹下
    showLoading: 发起该请求时是否在屏幕上方显示该请求对应的加载消息框，默认为true，可设置为false屏蔽消息框
*/

function setMockByData(data){
	if(data){
		if(data.mock){
			return true;
		}else{
			return false;
		}
	}else{
		return false;
	}
}

const api = {
    //测试请求
    testRequest: data => ajax.send({
        data,
        url: 'mock/mock1.json',
        method: 'get',
        auth: true,
        mock: true
    }),
	//登录
	login: data => ajax.login(data),
	//获取社区居民分类列表
	loadClassifyList: data => ajax.send({
		data,
		url: 'dev-api/admin/resident/list',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取领导列表
	loadLeaders: data => ajax.send({
        data,
        url: 'dev-api/admin/service-system/list',
        method: 'get',
        auth: true,
        mock: setMockByData(data)
    }),
	//获取关爱预警
    loadWarningList: data => ajax.send({
        data,
        url: 'mock/mock3.json',
        method: 'get',
        auth: true,
        mock: true
    }),
	//获取关爱保障信息（开发阶段，假数据）
    getCarenessData: data => ajax.send({
        data,
        url: 'mock/mock4.json',
        method: 'get',
        auth: true,
        mock: setMockByData(data)
    }),
	//获取车辆信息
    getCarTable: data => ajax.send({
        data,
        url: 'dev-api/admin/traffic-record/list',
        method: 'get',
        auth: true,
        mock: setMockByData(data)
    }),
	//获取小区事件列表
    getEventTable: data => ajax.send({
        data,
        url: 'dev-api/admin/random-clap/list',
        method: 'get',
        auth: true,
        mock: setMockByData(data)
    }),
	//获取党员列表
    getPartyMembers: data => ajax.send({
        data,
        url: 'dev-api/admin/party-member/list',
		mockUrl: 'mock/mock7.json',
        method: 'get',
        auth: true,
        mock: setMockByData(data)
    }),
	//获取近年人均收入情况
	getIncome: data => ajax.send({
		data,
		url: 'dev-api/admin/revenue-record/list',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取企业列表
	getCompanies: data => ajax.send({
		data,
		url: 'dev-api/admin/enterprise/list',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取品牌列表
	getCultureList: data => ajax.send({
		data,
		url: 'dev-api/admin/brand/list',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取社区概况
    getCommunityInfo: data => ajax.send({
        data,
        url: 'dev-api/admin/community-profile/',
        method: 'get',
        auth: true,
        mock: setMockByData(data)
    }),
	//获取环境数据
    getEnvironmentData: data => ajax.send({
        data,
        url: 'dev-api/admin/environmental-data/',
        method: 'get',
        auth: true,
        mock: setMockByData(data)
    }),
	//获取人才列表
    getTalentList: data => ajax.send({
        data,
        url: 'dev-api/admin/talent/list',
        method: 'get',
        auth: true,
        mock: setMockByData(data)
    }),
	//获取新发展党员
	getRecruitingMembers: data => ajax.send({
		data,
		url: 'dev-api/admin/recruiting-party-member/list',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取设备：广告机
	getADMachine: data => ajax.send({
		data,
		url: 'dev-api/admin/advertising-machine/list',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取设备：手环
	getBracelet: data => ajax.send({
		data,
		url: 'dev-api/admin/bracelet/list',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取设备：广播
	getBroadcast: data => ajax.send({
		data,
		url: 'dev-api/admin/broadcast/list',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取设备：摄像头
	getCameraDevice: data => ajax.send({
		data,
		url: 'dev-api/admin/camera/list',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取设备：智慧停车
	getSmartParking: data => ajax.send({
		data,
		url: 'dev-api/admin/smart-parking/list',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取设备：气象站
	getWeatherStation: data => ajax.send({
		data,
		url: 'dev-api/admin/weather-station/list',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取设备：水位传感器
	getWaterSensor: data => ajax.send({
		data,
		url: 'dev-api/admin/level-sensor/list',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取设备：电子围栏
	getEFence: data => ajax.send({
		data,
		url: 'dev-api/admin/electronic-fence/list',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取设备：水质传感器（mock）
	getWaterQualitySensor: data => ajax.send({
		data,
		url: 'mock/mock6.json',
		method: 'get',
		auth: true,
		mock: true
	}),
	//获取党费缴纳信息
	getPartyDue: data => ajax.send({
		data,
		url: 'dev-api/admin/party-due/list',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取涪昭帮信息
	getFuzhaobang: data => ajax.send({
		data,
		url: 'dev-api/admin/fuzhaobang/',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取监控视频直播地址列表
	getCameraUrl: data => ajax.send({
		data,
		url: 'dev-api/admin/live-broadcast/list',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取党建活动场数
	getPartyActivity: data => ajax.send({
		data,
		url: 'dev-api/admin/party-activity/',
		method: 'get',
		auth: true,
		mock: setMockByData(data)
	}),
	//获取楼栋详细信息
	getBuildingDetail: data => ajax.send({
		data,
		url: 'mock/mock8.json',
		method: 'get',
		auth: true,
		mock: true
	}),
	//获取社区公告
	getNotice: data => ajax.send({
		data,
		url: 'dev-api/admin/public-announcement/list',
		method: 'get',
		auth: true,
		mock: false
	}),
	//返贫提示
	returnPovertyHint: data => ajax.send({
		data,
		url: '/dev-api/admin/returnPovertyHint',
		method: 'get',
		auth: true,
		mock: false
	})
}

export default api;
